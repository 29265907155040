import React, { useEffect, useState } from 'react'
import "./style.css"
import { RiCloseLine, RiEdit2Line, RiFilter2Line } from "react-icons/ri";
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_REGIONS, GET_USERS } from '../graphql/query';
import { AREA_UPDATE, REGION_UPDATE } from '../graphql/mutation';

export default function Regions({user}) {
    const [selectId, setSelect] = useState(null)
    const [edit, setEdit] = useState(false)
    const { data, loading } = useQuery(GET_REGIONS)

    const select = data?.getRegions?.filter((e)=>e?.id === selectId)[0]

    const [search, setSearch] = useState([])

    const handleSearch = (query) => {
        const filteredResults = data?.getRegions?.filter(item =>
        item?.name?.toLowerCase().includes(query.toLowerCase())
        );

        setSearch(filteredResults);
    };

    if (loading) {
        return (
            <div className='Loading'>
                Loading...
            </div>
        )
    }
    return (
        <>
            <div className='Home'>
                <div className='HomeHead'>
                    REGIONS
                    <div className='FlexCon' />
                </div>
                <div className='UserDiv'>
                    <div className='NationDivLeft'>
                        <div className='UserSearchCon'>
                            <input onChange={(e)=>handleSearch(e?.target?.value)} placeholder='Search region' />
                            <div className='FlexCon' />
                            {/* <button><RiFilter2Line />Filter</button> */}
                        </div>
                        <div className='UserCon'>
                            <div className='UserHead'>
                                All Regions
                                <h4>{data?.getRegions?.length}</h4>
                                <div className='FlexCon' />
                                {/* <button onClick={()=>setOpen(true)}><RiAddCircleLine/> Create User</button> */}
                            </div>
                            <div className='NationHead2'>
                                <h2>Name</h2>
                                <h2>RD name</h2>
                                <h2>Area</h2>
                            </div>
                            {search?.length>0?
                            search?.map((item, index)=>{
                            return <User key={index} item={item} setSelect={setSelect}/>
                            })
                            :
                            data?.getRegions?.slice()?.sort((a,b)=>{
                                if ( a.name < b.name ){
                                    return -1;
                                }
                                if ( a.name > b.name ){
                                    return 1;
                                }
                                return 0;
                                })?.map((item, index) => {
                                return (
                                    <User key={index} item={item} setSelect={setSelect} />
                                )
                            })}
                        </div>
                    </div>
                    <div className='UserDivRight'>
                        {select ?
                            <>
                                <div className='UserDivRightProfile'>
                                    <h2>{select?.name}</h2>
                                    <div className='FlexCon' />
                                    {user?.role === 'super admin'?
                                    <div className='UserDivRightEdit' onClick={() => setEdit(select)}>
                                        <RiEdit2Line />
                                    </div>
                                    : null}
                                </div>
                                <div className='NationDivRightDiv'>
                                    <h4>RD name:</h4>
                                    <h5>{select?.rd?.name || '--'}</h5>
                                </div>
                            </> : null}
                    </div>
                </div>
            </div>
            {edit ? <Report setOpen={setEdit} open={edit} /> : null}
        </>
    )
}

function Report({ setOpen, open }) {
    const [name, setName] = useState("")
    const [nd, setNd] = useState("")
    const [area, setArea] = useState("")
    const [getData, { loading, data }] = useLazyQuery(GET_USERS)

    const [onUpdate] = useMutation(REGION_UPDATE,{
        refetchQueries:[
            {query:GET_REGIONS}
        ]
    })
    const [load, setLoad] = useState(false)

    useEffect(()=>{
        if(open){
            setName(open?.name)
            setNd(open?.rd?.id)
            getData()
        }
    },[open])

    return (
        <div className={'UserModal UserModalOpen'}>
            <div className='UserModalCon' style={{minWidth:300}}>
                <RiCloseLine className='UserModalClose' size={18} onClick={() => setOpen(false)} />
                <div className='UserModalHead'>
                    Edit Nation
                </div>
                {loading?
                <p style={{margin:10}}>Loading</p>
                :
                <>
                <div className='UserModalCon1'>
                    <div>
                        <h2>Area Name</h2>
                        <input value={name} onChange={(e) => setName(e.target.value)} placeholder='Country name' />
                    </div>
                    <div className='UserModalInput'>
                    <div>
                        <h2>Select RD</h2>
                        <select value={nd} onChange={(e)=>setNd(e?.target?.value)}>
                            <option>Select RD</option>
                            {data?.getUsers?.slice()?.sort((a,b)=>{
                                if ( a.name < b.name ){
                                    return -1;
                                }
                                if ( a.name > b.name ){
                                    return 1;
                                }
                                return 0;
                                })?.map((item, index)=>{
                                return <option key={index} value={item?.id}>{item?.name}</option>
                            })}
                        </select>
                    </div>
                    </div>
                </div>
                <div className='UserModalCon1'>
                    <div>
                        <h2>Select Area</h2>
                        <select value={area} onChange={(e)=>setArea(e?.target?.value)}>
                            <option>Select Area</option>
                            {data?.getAreas?.map((item, index)=>{
                                return <option key={index} value={item?.id}>{item?.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className='UserModalCon1'>
                    <div className='FlexCon'/>
                    <button onClick={()=>{
                        setLoad(true)
                        onUpdate({
                        variables:{
                            "updateRegionId": open?.id,
                            "region": {
                                "rd": nd || "",
                                "name": name || "",
                                "area": area || ""
                            }
                        }
                        }).then(()=>{
                            setLoad(false)
                            setOpen(false)
                            setName("")
                            setNd("")
                            setArea("")
                        }).catch((err)=>{
                            setLoad(false)
                            alert(err?.message)
                        })
                    }}>{load? "Loading" : "Update Area"}</button>
                </div>
                </>}
            </div>
        </div>
    )
}

function User({ item, setSelect, charter, setOpen }) {
    return (
        <div className='NationCon2' onClick={() => setSelect(item?.id)}>
            <div className='UserConImg'>
                <h2>{item?.name || '--'}</h2>
            </div>
            <h3>{item?.rd?.name || '--'}</h3>
            <h3>{item?.area?.name || '--'}</h3>
        </div>
    )
}