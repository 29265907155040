import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery } from '@apollo/client';

import Home from './pages/home';
import Sidebar from './layout/sidebar';
import Header from './layout/header';
import Users from './pages/users';
import Nations from './pages/nations';
import Login from './pages/login';
import Template from './pages/template';
import Nation from './pages/nation';
import Areas from './pages/areas';
import Regions from './pages/regions';
import User from './pages/user';
import { GET_ADMIN } from './graphql/query';
import Admin from './pages/admin';

function App() {
  const user = localStorage.getItem("charter_admin")

  const client = new ApolloClient({
    // uri: 'http://localhost:4000',
    uri: 'https://chartering.mykeystone.in',
    cache: new InMemoryCache(),
    headers: {
      Authorization: user || '',
    },
  });

  return(
    <ApolloProvider client={client}>
      <Navigation user={user}/>
    </ApolloProvider>
  )
}

function Navigation({user}){
  const {data, loading} = useQuery(GET_ADMIN)

  if(loading){
    return(
      <div className='Loading'>
        Loading
      </div>
    )
  }
  return (
    <Router>
      <div className="App">
        <Sidebar user={data?.getAdmin}/>
        <div className='AppBody'>
          <Header user={data?.getAdmin}/>
          {data?.getAdmin?
          <Routes>
            <Route path={'/'} element={<Home/>}/>
            <Route path={'/users'} element={<Users/>}/>
            <Route path={'/nations'} element={<Nations/>}/>
            <Route path={'/nation'} element={<Nation user={data?.getAdmin}/>}/>
            <Route path={'/areas'} element={<Areas user={data?.getAdmin}/>}/>
            <Route path={'/admin'} element={<Admin user={data?.getAdmin}/>}/>
            <Route path={'/regions'} element={<Regions user={data?.getAdmin}/>}/>
            <Route path={'/user'} element={<User user={data?.getAdmin}/>}/>
            <Route path={'/templates'} element={<Template/>}/>
            <Route path={'*'} element={<Navigate to={'/'}/>}/>
          </Routes>
          :
          <Routes>
            <Route path={'/login'} element={<Login/>}/>
            <Route path={'*'} element={<Navigate to={'/login'}/>}/>
          </Routes>
          }
        </div>
      </div>
    </Router>
  );
}

export default App;
