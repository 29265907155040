import React, { useEffect, useState } from 'react'
import "./style.css"
import { RiAddCircleLine, RiCloseLine, RiEdit2Line, RiFilter2Line } from "react-icons/ri";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useQuery } from '@apollo/client';
import { GET_ALL_USERS } from '../graphql/query';

export default function Users() {
  const [open, setOpen] = useState(false)
  const {data,loading} = useQuery(GET_ALL_USERS)
  const [select, setSelect] = useState()

  if(loading){
    return (
      <div className='Loading'>
        Loading...
      </div>
    )
  }
  return (
    <>
    <div className='Home'>
      <div className='HomeHead'>
        USERS
        <div className='FlexCon' />
      </div>
      <div className='UserDiv'>
        <div className='UserDivLeft'>
          <div className='UserSearchCon'>
            <input placeholder='Search user, email' />
            <div className='FlexCon' />
            <button><RiFilter2Line/>Filter</button>
          </div>
          <div className='UserCon'>
            <div className='UserHead'>
              All Users
              <h4>{data?.getCharterUser?.length}</h4>
              <div className='FlexCon' />
              <button onClick={()=>setOpen(true)}><RiAddCircleLine/> Create User</button>
            </div>
            <div className='UserHead1'>
              <h2>Name</h2>
              <h2>Role</h2>
              <h2>Email</h2>
              <h2>Status</h2>
              <h2>Actions</h2>
            </div>
            {data?.getCharterUser?.map((item, index)=>{
              return(
                <User key={index} item={item} setSelect={setSelect}/>
              )
            })}
          </div>
        </div>
        <div className='UserDivRight'>
          {select?
          <>
          <div className='UserDivRightProfile'>
            <img src='https://ittacademy.net.au/wp-content/uploads/2022/12/bio-placeholder-img.jpg'/>
            <div>
              <h2>{select?.name}</h2>
              <h3>Role: {select?.role}</h3>
            </div>
            <div className='FlexCon'/>
            <div className='UserDivRightEdit' onClick={()=>setOpen(select)}>
              <RiEdit2Line/>
            </div>
          </div>
          <div className='UserDivRightDiv'>
            <h4>Email:</h4>
            <h5>{select?.email}</h5>
          </div>
          <div className='UserDivRightDiv'>
            <h4>Status:</h4>
            <h5>{select?.status}</h5>
          </div>
          <div className='UserDivRightDiv'>
            <h4>Country:</h4>
            <h5>{select?.country}</h5>
          </div>
          <div className='UserDivRightDiv'>
            <h4>ND:</h4>
            <h5>{select?.NDName}</h5>
          </div>
          <div className='UserDivRightDiv'>
            <h4>RD:</h4>
            <h5>{select?.RDName}</h5>
          </div>
          <div className='UserDivRightDiv'>
            <h4>AD:</h4>
            <h5>{select?.ADName}</h5>
          </div>
          </>
          : null}
        </div>
      </div>
    </div>
    <div className={open?'UserModal UserModalOpen':'UserModal'}>
      <CreateUser setOpen={setOpen} open={open}/>
    </div>
    </>
  )
}

function CreateUser({setOpen, open}){
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [role, setRole] = useState("")
  const [country, setCountry] = useState("")
  const [status, setStatus] = useState("")
  const [currency, setCurrency] = useState("")
  const [exchange, setExchange] = useState("")

  useEffect(()=>{
    setName(open?.name)
    setEmail(open?.email)
    setRole(open?.role)
    setCountry(open?.country)
    setStatus(open?.status)
    setCurrency(open?.currency)
    setExchange(open?.exchange)
  },[open])

  return(
    <div className='UserModalCon'>
    <RiCloseLine className='UserModalClose' size={18} onClick={()=>setOpen(false)}/>
    <div className='UserModalHead'>
      {open?.name? "Edit User" : "Add User"}
    </div>
      <div className='UserModalCon1'>
        <div>
          <h2>User Name</h2>
          <input value={name} onChange={(e)=>setName(e.target.value)} placeholder='Enter User Name'/>
        </div>
        <div className='UserModalInput'>
          <h2>Email Address</h2>
          <input value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Enter Email Address'/>
        </div>
      </div>
      <div className='UserModalCon1'>
        <div>
        <h2>Password</h2>
          <input value={password} onChange={(e)=>setPassword(e.target.value)} placeholder='Create Password'/>
        </div>
      </div>
      <div className='UserModalCon1'>
        <div className='FlexCon'/>
        {open?.name? <button>Update User</button> : <button>Create User</button>}
      </div>
    </div>
  )
}

function User({item, setSelect}){
  return(
    <div className='UserCon1' onClick={()=>setSelect(item)}>
      <div className='UserConImg'>
        {/* <img src='https://st4.depositphotos.com/4329009/19956/v/450/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg'/> */}
        <h2>{item?.name}</h2>
      </div>
      <h3>{item?.role}</h3>
      <h3>{item?.email}</h3>
      <h4>Active</h4>
      <HiOutlineDotsVertical color='#000' size={13} style={{backgroundColor:"#dddddd", borderRadius:5, padding:5, cursor:"pointer", marginLeft:25}} onClick={(e)=>{
        e.preventDefault();
        console.log("ok")
      }}/>
    </div>
  )
}

const nations = [
  "Papua New Guinea",
  "Croatia",
  "Sri Lanka*",
  "Nepal*",
  "Lebanon",
  "Canada",
  "Singapore",
  "Ukraine",
  "Mauritius",
  "Iraq*",
  "São Tomé and Príncipe",
  "Angola",
  "Armenia",
  "Benin",
  "Kazakhstan*",
  "Benin",
  "Portugal",
  "Indonesia*",
  "Italy",
  "Ethiopia",
  "Comoros*",
  "Belarus*",
  "Rwanda",
  "Taiwan",
  "Belgium",
  "Pakistan*",
  "Guinea",
  "Moldova",
  "Bangladesh*",
  "Burundi",
  "Botswana",
  "Nauru",
  "Madagascar",
  "Hungary",
  "USA",
  "France",
  "Djibouti",
  "Ukraine",
  "Sierra Leone",
  "Liberia",
  "Kosovo",
  "Mongolia",
  "St. Vincent & Grenadines",
  "Kenya",
  "Paraguay",
  "Gambia",
  "Tonga",
  "Puerto Rico",
  "Malawi",
  "Ireland",
  "Namibia",
  "Slovakia",
  "Guatemala",
  "Haiti",
  "Trinidad & Tobago",
  "Czechia",
  "South Sudan*",
  "Mozambique",
  "Mauritius",
  "Cameroon",
  "American Samoa",
  "Cyprus",
  "Bolivia",
  "Dominica",
  "Bhutan",
  "Chile",
  "Georgia",
  "Jamaica",
  "Korea",
  "Eswatini",
  "Malawi",
  "Albania",
  "Ecuador",
  "Spain",
  "Poland",
  "Barbados",
  "Zimbabwe",
  "Croatia",
  "Germany",
  "Bhutan",
  "Brazil",
  "Italy",
  "Egypt*",
  "South Africa",
  "Fiji",
  "Afghanistan*",
  "Philippines",
  "Poland",
  "Denmark",
  "Serbia",
  "Northern Ireland",
  "Nigeria",
  "Reunion",
  "Uganda",
  "Malaysia*",
  "Rwanda",
  "UAE*",
  "Honduras",
  "Myanmar*",
  "North Macedonia",
  "Tanzania",
  "Ghana",
  "The Netherlands",
  "Romania",
  "Australia",
  "Mexico",
  "Samoa",
  "Britain",
  "India",
  "Kyrgyzstan*",
  "Guinea Bissau",
  "Afghanistan*",
  "Uruguay",
  "Samoa",
]