import React,{useState} from 'react'
import './style.css'
import { RiEdit2Line, RiCloseLine } from "react-icons/ri";

export default function Template() {
  const [edit, setEdit] = useState(false)
  return (
    <>
    <div className='Template'>
      <div className='Template_Con'>
        <div className='Template_Head'>
          <h2>Pre Open Mail</h2>
          <RiEdit2Line onClick={()=>setEdit('Pre Open Mail')} className='Template_Head_Icon'/>
        </div>
        <div dangerouslySetInnerHTML={{ __html: preOpen }} />
        <div className='Template_Head'>
          <h2>Open Mail</h2>
          <RiEdit2Line onClick={()=>setEdit('Open Mail')} className='Template_Head_Icon'/>
        </div>
        <div dangerouslySetInnerHTML={{ __html: OpenEmail }} />
        <div className='Template_Head'>
          <h2>Welcome Mail</h2>
          <RiEdit2Line onClick={()=>setEdit('Welcome Mail')} className='Template_Head_Icon'/>
        </div>
        <div dangerouslySetInnerHTML={{ __html: WelcomeEmail }} />
        <div className='Template_Head'>
          <h2>OTP Mail</h2>
          <RiEdit2Line onClick={()=>setEdit('OTP Mail')} className='Template_Head_Icon'/>
        </div>
        <div dangerouslySetInnerHTML={{ __html: OTPEmail }} />
        <div className='Template_Head'>
          <h2>Submit Mail</h2>
          <RiEdit2Line onClick={()=>setEdit('Submit Mail')} className='Template_Head_Icon'/>
        </div>
        <div dangerouslySetInnerHTML={{ __html: SubmitEmail }} />
        <div className='Template_Head'>
          <h2>Review Mail</h2>
          <RiEdit2Line onClick={()=>setEdit('Review Mail')} className='Template_Head_Icon'/>
        </div>
        <div dangerouslySetInnerHTML={{ __html: ReviewEmail }} />
        <div className='Template_Head'>
          <h2>Complete Mail</h2>
          <RiEdit2Line onClick={()=>setEdit('Complete Mail')} className='Template_Head_Icon'/>
        </div>
        <div dangerouslySetInnerHTML={{ __html: CompleteEmail }} />
      </div>
    </div>
    <div className={edit?'UserModal UserModalOpen':'UserModal'}>
      <EditEmail setOpen={setEdit} open={edit}/>
    </div>
    </>
  )
}

function EditEmail({setOpen, open}){
  return (
    <div className='UserModalCon'>
      <div className='UserModalHead'>
        Edit Template
      </div>
      <div className='Template_Input_Con'>
        <h2>Title</h2>
        <input value={templates?.find((e)=> e?.id === open)?.title}/>
      </div>
      <div className='Template_Input_Con'>
        <h2>Body</h2>
        <textarea value={templates?.find((e)=> e?.id === open)?.discr}/>
      </div>
      <div className='UserModalCon1'>
        <div className='FlexCon'/>
        <button>Save</button>
      </div>
      <RiCloseLine className='UserModalClose' size={18} onClick={()=>setOpen(false)}/>
    </div>
  )
}

const templates = [
  {
    id:"Pre Open Mail",
    title: `Chartering 2023 will open soon`,
    discr: `We are grateful for the work you do and who you are! Thank you! 

Online Chartering will open soon - <a href="https://thisischartering.com" target="_blank">thisischartering.com</a>

Quick tips:
• You will receive your login credentials when chartering opens. If at any time you forget your password, click on “forgot password,” enter your email, and you will receive informaiton on how to reset your password.<br>
• For the Financial Information section, please input your financial information in your nation’s currency. We will do the exchange rate conversions later. 

In order to submit the forms as complete, both the approval of the National Director and the Board Chair are required. The Board Chair will approve the chartering report after the National Director has entered all the information. Each will need to log in separately to submit the report. Please have the online forms completed by March 31. 

We understand all the work and effort it takes each year for you to fill in these forms, submit your latest financials, pay your charter fee, and more! Your Area Team is available to work with you and help your national program complete everything. Also, our chartering team is here to help if you need it – so please don’t hesitate to write if you need help!

If you have additional questions, email us at <a href="mailto:chartering@yfci.org">chartering@yfci.org</a>.

Cheers,
The Chartering Team`
  },
  {
    id:"Open Mail",
    title: `Chartering is now open at ThisIsChartering.com!`,
    discr: `Great news! YFC Chartering for 2023 has just opened up at [thisischartering]. Don't remember your login details? Don't worry - we'll be sending your login credentials in a separate email shortly. Keep an eye out for it, and get ready to complete your chartering information.
Cheers,
The Chartering Team`
  },
  {
    id:"Welcome Mail",
    title: `Welcome to Chartering`,
    discr: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mattis ante sed imperdiet euismod. Vivamus fermentum bibendum turpis, et tempor dui. Sed vitae lectus egestas, finibus purus ac, rutrum mauris.

Your password is: "#######"`
  },
  {
    id:"OTP Mail",
    title: `YFCI chartering`,
    discr: `The verification code is ####`
  },
  {
    id:"Submit Mail",
    title: `Dear [BC Name],`,
    discr: `I hope this email finds you well. I am writing to inform you that National Director [ND Name] of [Country] has successfully completed the chartering report. We kindly request that you be able to review the charter at your earliest convenience

If everything is in order, please proceed with signing and submitting the report. Should any issues arise, feel free to reach out to us for assistance.

Thank you for your attention to this matter.

Best regards,
The Chartering Team`
  },
  {
    id:"Review Mail",
    title: `Dear [ND Name],`,
    discr: `I hope this email finds you well. I am writing to inform you that Board Chair [BC Name] of [Country] has request you to take some time to revisit your answers.

Reason: [Reason]

Best regards,
The Chartering Team`
  },
  {
    id:"Complete Mail",
    title: `Greetings!`,
    discr: `We are pleased to inform you that the chartering process is now complete for [Country]. We would like to thank everyone involved in making this process successful.

Click this <a href="https://www.africau.edu/images/default/sample.pdf" target="_blank">link</a> for the PDF document with all the details of the chartering process.

Please take a moment to review it and let us know if you have any questions or concerns.

Once again, we would like to thank everyone who was involved for their hard work and dedication in making the chartering process a success.

Sincerely,
The Chartering Team`
  },
]

const title = `Chartering 2023 will open soon`
const discr = `We are grateful for the work you do and who you are! Thank you! 

Online Chartering will open soon - <a href="https://thisischartering.com" target="_blank">thisischartering.com</a>

Quick tips:
• You will receive your login credentials when chartering opens. If at any time you forget your password, click on “forgot password,” enter your email, and you will receive informaiton on how to reset your password.<br>
• For the Financial Information section, please input your financial information in your nation’s currency. We will do the exchange rate conversions later. 

In order to submit the forms as complete, both the approval of the National Director and the Board Chair are required. The Board Chair will approve the chartering report after the National Director has entered all the information. Each will need to log in separately to submit the report. Please have the online forms completed by March 31. 

We understand all the work and effort it takes each year for you to fill in these forms, submit your latest financials, pay your charter fee, and more! Your Area Team is available to work with you and help your national program complete everything. Also, our chartering team is here to help if you need it – so please don’t hesitate to write if you need help!

If you have additional questions, email us at <a href="mailto:chartering@yfci.org">chartering@yfci.org</a>.

Cheers,
The Chartering Team`



const WelcomeEmail =`<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>

  <!--[if gte mso 9]>
  <xml>
    <o:OfficeDocumentSettings>
      <o:AllowPNG/>
      <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
  </xml>
  <![endif]-->

  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="x-apple-disable-message-reformatting">
  <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->

    <!-- Your title goes here -->
    <title>Newsletter</title>
    <!-- End title -->

    <!-- Start stylesheet -->
    <style type="text/css">
      a,a[href],a:hover, a:link, a:visited {
        /* This is the link colour */
        text-decoration: none!important;
      }
      .link {
        text-decoration: underline!important;
      }
      p, p:visited {
        /* Fallback paragraph style */
        font-size:15px;
        line-height:24px;
        font-family:'Helvetica', Arial, sans-serif;
        font-weight:300;
        text-decoration:none;
        color: #000000;
      }
      h1 {
        /* Fallback heading style */
        font-size:22px;
        line-height:24px;
        font-family:'Helvetica', Arial, sans-serif;
        font-weight:normal;
        text-decoration:none;
        color: #000000;
      }
      .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td {line-height: 100%;}
      .ExternalClass {width: 100%;}
    </style>
    <!-- End stylesheet -->

</head>

  <!-- You can change background colour here -->
  <body style="text-align: center; margin: 0; padding-top: 10px; padding-bottom: 10px; padding-left: 0; padding-right: 0; -webkit-text-size-adjust: 100%;background-color: #f2f4f6; color: #000000" align="center">
  
  <!-- Fallback force center content -->
  <div style="text-align: center;">
    
    <!-- Start container for logo -->
    <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;" width="600">
      <tbody>
        <tr>
          <td style="width: 596px; vertical-align: top; padding-left: 0; padding-right: 0; padding-top: 15px; padding-bottom: 15px;" width="596">

            <!-- Your logo is here -->
            <img style="width: 225px; max-width: 225px; height: 37.5px; max-height: 37.5px; text-align: center; color: #ffffff;" alt="Logo" src="https://lh3.googleusercontent.com/fife/AGXqzDlfRrEHMHBwJIlYkFst8Z_itISNPUJ-kC66LgcgMEGvPO7PZF5_oIthfWuMhlUQWKKbEQY0UOiqgNgBfGHaJq_VnW2EVstgjSfua2NY9xHN5b2IwfknO1_qNQKUYA5Sy4HYQxQ5IVkYd1wWJLxRMQAiY4A7FEjurNG9mYJ65woCcZejXbMwP3wT8LcmRiTbhKBIhnsdfLAQ_RZ1AtJquUJpqOH5nDoyEbWdUW2PayTTb22dpLGgyjzXbscoycMQlCFY0b1eHvSFk_9-ShMRNZh6GnWRY0iKzKkt5vtS644-aSx-GrC8o48IzvRtHsGGQ9RtWu_5utITN0Ga3-Ka-ju5boNGru1b7M3zz_RzZcRN6THseHj1cL6_9zZW8LOUf4uuSj7HqM_5PiyoXBj-kT12yzSVQk4g3BzUGeVfmAQ-88PC1Xq7ZFebDWSN7KTv8AknPWoyy22_u7f-cl3cWeZH_cVhMT7YzAckGw47Gpd-pARazQDk_-bUScmWdeHW0dIdicxzm2QIfCVqLqYsVxXEX5AXLkkkm_ue4DBNAqGEuKsImZDxzsQzJDOU94nzaboHuT06cvWmslC53JabY3w0MmTPIQVisNpBbGqn_4UGM_d-waiOkPdl0gIYH7MkM4UYAQrqfm0xMhKzy0GfQnyQQQRnJRcmelhqtSafXjnWURawIDnxDWHrnicIK2c9LpV_GJTQ7lcyU_rmw64K0FRdNSYl62p42CdSWXXP4LPQjmvjKehRd4X5Du1Lp9HPz5TdUMu12HhV1dSO_UX1TQ7W1CcPrtJSo9MVBDH9zqf-A36f013yBLQeL8A6gNX5knJWj65KQKPoe_74En8oyw0qNjKZlcPWHkvCtBNxXpXAtjHp40F5Z6-vtqwuKF7ofbatMyp_KnsGr_ABY-uXBwxv6yTbS5iay6S7B3V_DvPb79IWP97G2Ov0XazB_FJtc6vjmNma-Vi0602z1_j8JzfL9PLlmYgVYSQlcxKDMEzPUcwCDlSOoWsuLRhc3ZTKTvYbwSfBqw-LszB-ZQMICpaiYXwZ2ti6rI-iRObPrGfvySNbg801_XZF_iTJaFMcyir1CZ1PUROM_Mi-YYPOhNLk1G599GmBb28IHc15uKlXLbtUoWD1Gqt6OQq7iqqIGa-bsTs2jdbWvA2wSuUmXRMkXxxAf8d7-y2HaKMMYBG_LhPcwaoD_Ci4MTxPuQ5xg6yDgxhTjsA8IUTxgJWdpC3FN_G0dPVNX6sdfwhbDnlo72AyeQDnWx3cxs_tPiWMoziX6ZXkM2CetPrHdnthbWF6MGx6G2cfTMiWfSHj-bw8Ls2pFOsn5fllVDN_6o_GOMUAW9JqNPmye8ft1KeYvaCnECsUUCSLMXg04WFyxTZmvdRrOhZbCXMAgpRtY-km4CqRj33xeysEhb6ju-JppbXnz6Sqtt51XlIzMidNS0LV8XxGyPicEq5vrPZFrNw-csI5xbxwxWwim5tI2pz0PAlCcozVhqdTjLgk5J9_25z23KVv1T0tz5FjwOot632DwIc5jqUFvUOz0osWSFumMeviyy3zq9lhYrQAkHSi1w=w2876-h1638" align="center" width="225" height="37.5">

          </td>
        </tr>
      </tbody>
    </table>
    <!-- End container for logo -->

    <!-- Start single column section -->
    <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;" width="600">
        <tbody>
          <tr>
            <td style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 0px; padding-bottom: 40px;" width="596">

              <h1 style="font-size: 20px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 600; text-decoration: none; color: #000000;">Welcome to Chartering</h1>

              <p style="font-size: 15px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #919293;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mattis ante sed imperdiet euismod. Vivamus fermentum bibendum turpis, et tempor dui. Sed vitae lectus egestas, finibus purus ac, rutrum mauris.</p>              

              <p style="font-size: 15px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #919293;">Your password is: "#######"</p>

              <!-- Start button (You can change the background colour by the hex code below) -->
              <a href="https://www.thisischartering.com" target="_blank" style="background-color: #000000; font-size: 15px; line-height: 22px; font-family: 'Helvetica', Arial, sans-serif; font-weight: normal; text-decoration: none; padding: 12px 15px; color: #ffffff; border-radius: 5px; display: inline-block; mso-padding-alt: 0;">
                  <!--[if mso]>
                  <i style="letter-spacing: 25px; mso-font-width: -100%; mso-text-raise: 30pt;">&nbsp;</i>
                <![endif]-->

                  <span style="mso-text-raise: 15pt; color: #ffffff;">Start filling</span>
                  <!--[if mso]>
                  <i style="letter-spacing: 25px; mso-font-width: -100%;">&nbsp;</i>
                <![endif]-->
              </a>
              <!-- End button here -->

            </td>
          </tr>
        </tbody>
      </table>
      <!-- End single column section -->

      <!-- Start footer -->
      <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #000000;" width="600">
        <tbody>
          <tr>
            <td style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 30px; padding-bottom: 30px;" width="596">

              <!-- Your inverted logo is here -->
              <img style="width: 225px; max-width: 225px; height: 37.5px; max-height: 37.5px; text-align: center; color: #ffffff;" alt="Logo" src="https://lh3.googleusercontent.com/fife/AGXqzDlx-zSPxNtGZOTpi1kV5KYmC2tPWs18BVSFSjhrAbLnwmKGFovmIXY6PxdIGG99jcn9oSNZVtvrpVRePFN-nyXppp4ef-ih6tqj9jF4YYU9zhmgXRLSg2ZTkHg3IIfOrQ9i8l0mHR1D7IDGyNBFdr3b9F1NrXehFQ-6hkhSYGDlLPOvpuxV_EYuBx2TW3TYTjHsj-NPC3qdChrTWsa8-n8wdVjSmOlkDbQ7N0cbxNubtF4zr_MDGU5f1vSz2WC9jolst078xyUVkp_Qg0dNyZHMBBXTisNoWMHPtHMG72kCAz-1p7e3G0cPko_QPGnMQvP3BX6B7CJLh-WRt19z0s0gLBqteEb7GAltHOK_IsBx_gnUDPlAa0XwqpBUfUyFFovcpDpD7Al0B3Z8KONirXXNcuVgroTtfhnfvZ83-ARPoF-gRd15jhiVpInMz6sdOpyc625g1mdpxF-C7xfdeBkmO6FSEUrPuVjEDJ-pbJ_u3kxjeQrvbqSSSdacP7cqllB03VVZiQsgsyGr9TCG5c9WyLzS5A0vb1zF9VVMcWKKDFROKeOIp0YW76V-dQwulpWltfegobkQ1lHEyob-UG2TwdI6orePz2uowOhR4QpuRah65hgBh84lVt0h5QE0zGHQy0X-46V-1qr8xOwa5rMaVdOX9u__Uu8ezLL-HZm9C2xnBA8Gkx90-qBKdKNVDs_oQead7VyU-INqh7VU1PRKltoWEAkMIUKK8fS7gxVKPwFSaiCrCtVSHuYdh3Cn5JWRleO6bYJH1jBfsAfwYHXl8x0l-IF66P31qd_45c5jrScOzcXh210s-l4qr7LYK-8fPeLeArp0iN8QUX1-uXgzzD397HC7qvK629w7louJovc7LFQz1KkiNGBEc-TJFtEa-gfX1GZnWnebSgjMW8SIg0y2dLkV2YlPeJCWdKag9lgIahsU0JIWPh5Tl-soK1Y5UvldJCDuPNwRrlDSHB0QYaLg25_DO13rMaXZfIiWroVCJa_j6-qaVd44ezo1JuSZyyKuKAJminksxXD2WO8Hfktj_Ub-JBGu8MvFINZH682YxtjX-Bi71eeuHWlTWPXpsMml36LYzHPeOPwt6ou2R-wWLnB7PzJtmbToJmqv7C06FTSEV60cNBc4Tmx14qbtYf8gNBOOfxQR0SoEw-HxMHZO0stGE_EuU0rX0oO7Ztcvp4phxNC8o6-_HkHXsMBzqmj28e7X9aFnQRaK7ZUY4D_bpURqglsqwZ_oyTu8PtqCYGgDM0eUe11-IejfObT0Zcyl_XvT4KlH3F199SFUx_4-zJqa6i6wnmFTqPREC8Bi6nioh15oLCFGjAgVOmqnvBiszRUH8DPodGTqb_BZJ1iQwxYNMO7rLnuJ4-DOIfkSL3QCR4zUM63hwOLnNWqIlckxJ3yfnIc14PEK3nANFwPXNeuIEuxMihi5r8k2DVcXPABJtuUaC1EQsty_B2Mw4Z5-QhgDxfYoVOOKZ0E8NSi3No0RHC6hXPadF4Gf5lAG5TkmUeRETfKN2_MJ2jenbbZzjPiQz-NFWcCGDYPVx5BhRTEwX5X6gf1Acg=w2876-h1128" align="center" width="180" height="85">

              <p style="font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
                PO Box 4555, Englewood, CO 80155 USA
              </p>

              <p style="margin-bottom: 0; font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
                <a target="_blank" style="text-decoration: underline; color: #ffffff;" href="https://www.yfci.org">
                  www.yfci.org
                </a>
              </p>

            </td>
          </tr>
        </tbody>
      </table>
      <!-- End footer -->
  </div>

  </body>

</html>`

      const OpenEmail = `<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
      
      <head>
      
        <!--[if gte mso 9]>
        <xml>
          <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
        </xml>
        <![endif]-->
      
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta name="x-apple-disable-message-reformatting">
        <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->
      
          <!-- Your title goes here -->
          <title>Newsletter</title>
          <!-- End title -->
      
          <!-- Start stylesheet -->
          <style type="text/css">
            a,a[href],a:hover, a:link, a:visited {
              /* This is the link colour */
              text-decoration: none!important;
            }
            .link {
              text-decoration: underline!important;
            }
            p, p:visited {
              /* Fallback paragraph style */
              font-size:15px;
              line-height:24px;
              font-family:'Helvetica', Arial, sans-serif;
              font-weight:300;
              text-decoration:none;
              color: #000000;
            }
            h1 {
              /* Fallback heading style */
              font-size:22px;
              line-height:24px;
              font-family:'Helvetica', Arial, sans-serif;
              font-weight:normal;
              text-decoration:none;
              color: #000000;
            }
            .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td {line-height: 100%;}
            .ExternalClass {width: 100%;}
          </style>
          <!-- End stylesheet -->
      
      </head>
      
        <!-- You can change background colour here -->
        <body style="text-align: center; margin: 0; padding-top: 10px; padding-bottom: 10px; padding-left: 0; padding-right: 0; -webkit-text-size-adjust: 100%;background-color: #f2f4f6; color: #000000" align="center">
        
        <!-- Fallback force center content -->
        <div style="text-align: center;">
          
          <!-- Start container for logo -->
          <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;" width="600">
            <tbody>
              <tr>
                <td style="width: 596px; vertical-align: top; padding-left: 0; padding-right: 0; padding-top: 15px; padding-bottom: 15px;" width="596">
      
                  <!-- Your logo is here -->
                  <img style="width: 225px; max-width: 225px; height: 37.5px; max-height: 37.5px; text-align: center; color: #ffffff;" alt="Logo" src="https://lh3.googleusercontent.com/fife/AGXqzDlfRrEHMHBwJIlYkFst8Z_itISNPUJ-kC66LgcgMEGvPO7PZF5_oIthfWuMhlUQWKKbEQY0UOiqgNgBfGHaJq_VnW2EVstgjSfua2NY9xHN5b2IwfknO1_qNQKUYA5Sy4HYQxQ5IVkYd1wWJLxRMQAiY4A7FEjurNG9mYJ65woCcZejXbMwP3wT8LcmRiTbhKBIhnsdfLAQ_RZ1AtJquUJpqOH5nDoyEbWdUW2PayTTb22dpLGgyjzXbscoycMQlCFY0b1eHvSFk_9-ShMRNZh6GnWRY0iKzKkt5vtS644-aSx-GrC8o48IzvRtHsGGQ9RtWu_5utITN0Ga3-Ka-ju5boNGru1b7M3zz_RzZcRN6THseHj1cL6_9zZW8LOUf4uuSj7HqM_5PiyoXBj-kT12yzSVQk4g3BzUGeVfmAQ-88PC1Xq7ZFebDWSN7KTv8AknPWoyy22_u7f-cl3cWeZH_cVhMT7YzAckGw47Gpd-pARazQDk_-bUScmWdeHW0dIdicxzm2QIfCVqLqYsVxXEX5AXLkkkm_ue4DBNAqGEuKsImZDxzsQzJDOU94nzaboHuT06cvWmslC53JabY3w0MmTPIQVisNpBbGqn_4UGM_d-waiOkPdl0gIYH7MkM4UYAQrqfm0xMhKzy0GfQnyQQQRnJRcmelhqtSafXjnWURawIDnxDWHrnicIK2c9LpV_GJTQ7lcyU_rmw64K0FRdNSYl62p42CdSWXXP4LPQjmvjKehRd4X5Du1Lp9HPz5TdUMu12HhV1dSO_UX1TQ7W1CcPrtJSo9MVBDH9zqf-A36f013yBLQeL8A6gNX5knJWj65KQKPoe_74En8oyw0qNjKZlcPWHkvCtBNxXpXAtjHp40F5Z6-vtqwuKF7ofbatMyp_KnsGr_ABY-uXBwxv6yTbS5iay6S7B3V_DvPb79IWP97G2Ov0XazB_FJtc6vjmNma-Vi0602z1_j8JzfL9PLlmYgVYSQlcxKDMEzPUcwCDlSOoWsuLRhc3ZTKTvYbwSfBqw-LszB-ZQMICpaiYXwZ2ti6rI-iRObPrGfvySNbg801_XZF_iTJaFMcyir1CZ1PUROM_Mi-YYPOhNLk1G599GmBb28IHc15uKlXLbtUoWD1Gqt6OQq7iqqIGa-bsTs2jdbWvA2wSuUmXRMkXxxAf8d7-y2HaKMMYBG_LhPcwaoD_Ci4MTxPuQ5xg6yDgxhTjsA8IUTxgJWdpC3FN_G0dPVNX6sdfwhbDnlo72AyeQDnWx3cxs_tPiWMoziX6ZXkM2CetPrHdnthbWF6MGx6G2cfTMiWfSHj-bw8Ls2pFOsn5fllVDN_6o_GOMUAW9JqNPmye8ft1KeYvaCnECsUUCSLMXg04WFyxTZmvdRrOhZbCXMAgpRtY-km4CqRj33xeysEhb6ju-JppbXnz6Sqtt51XlIzMidNS0LV8XxGyPicEq5vrPZFrNw-csI5xbxwxWwim5tI2pz0PAlCcozVhqdTjLgk5J9_25z23KVv1T0tz5FjwOot632DwIc5jqUFvUOz0osWSFumMeviyy3zq9lhYrQAkHSi1w=w2876-h1638" align="center" width="225" height="37.5">
      
                </td>
              </tr>
            </tbody>
          </table>
          <!-- End container for logo -->
      
          <!-- Start single column section -->
          <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;" width="600">
              <tbody>
                <tr>
                  <td style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 0px; padding-bottom: 40px;" width="596">
      
                    <h1 style="font-size: 20px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 600; text-decoration: none; color: #000000;">Chartering is now open at ThisIsChartering.com!</h1>
      
                    <p style="font-size: 15px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #919293;">Great news! YFC Chartering for 2023 has just opened up at [thisischartering]. Don't remember your login details?  Don't worry - we'll be sending your login credentials in a separate email shortly. Keep an eye out for it, and get ready to complete your chartering information.<br/>Cheers,<br/>The Chartering Team</p>              
                    <!-- Start button (You can change the background colour by the hex code below) -->
                    <a href="https://www.thisischartering.com" target="_blank" style="background-color: #000000; font-size: 15px; line-height: 22px; font-family: 'Helvetica', Arial, sans-serif; font-weight: normal; text-decoration: none; padding: 12px 15px; color: #ffffff; border-radius: 5px; display: inline-block; mso-padding-alt: 0;">
                        <!--[if mso]>
                        <i style="letter-spacing: 25px; mso-font-width: -100%; mso-text-raise: 30pt;">&nbsp;</i>
                      <![endif]-->
      
                        <span style="mso-text-raise: 15pt; color: #ffffff;">Start filling</span>
                        <!--[if mso]>
                        <i style="letter-spacing: 25px; mso-font-width: -100%;">&nbsp;</i>
                      <![endif]-->
                    </a>
                    <!-- End button here -->
      
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- End single column section -->
      
            <!-- Start footer -->
            <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #000000;" width="600">
              <tbody>
                <tr>
                  <td style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 30px; padding-bottom: 30px;" width="596">
      
                    <!-- Your inverted logo is here -->
                    <img style="width: 225px; max-width: 225px; height: 37.5px; max-height: 37.5px; text-align: center; color: #ffffff;" alt="Logo" src="https://lh3.googleusercontent.com/fife/AGXqzDlx-zSPxNtGZOTpi1kV5KYmC2tPWs18BVSFSjhrAbLnwmKGFovmIXY6PxdIGG99jcn9oSNZVtvrpVRePFN-nyXppp4ef-ih6tqj9jF4YYU9zhmgXRLSg2ZTkHg3IIfOrQ9i8l0mHR1D7IDGyNBFdr3b9F1NrXehFQ-6hkhSYGDlLPOvpuxV_EYuBx2TW3TYTjHsj-NPC3qdChrTWsa8-n8wdVjSmOlkDbQ7N0cbxNubtF4zr_MDGU5f1vSz2WC9jolst078xyUVkp_Qg0dNyZHMBBXTisNoWMHPtHMG72kCAz-1p7e3G0cPko_QPGnMQvP3BX6B7CJLh-WRt19z0s0gLBqteEb7GAltHOK_IsBx_gnUDPlAa0XwqpBUfUyFFovcpDpD7Al0B3Z8KONirXXNcuVgroTtfhnfvZ83-ARPoF-gRd15jhiVpInMz6sdOpyc625g1mdpxF-C7xfdeBkmO6FSEUrPuVjEDJ-pbJ_u3kxjeQrvbqSSSdacP7cqllB03VVZiQsgsyGr9TCG5c9WyLzS5A0vb1zF9VVMcWKKDFROKeOIp0YW76V-dQwulpWltfegobkQ1lHEyob-UG2TwdI6orePz2uowOhR4QpuRah65hgBh84lVt0h5QE0zGHQy0X-46V-1qr8xOwa5rMaVdOX9u__Uu8ezLL-HZm9C2xnBA8Gkx90-qBKdKNVDs_oQead7VyU-INqh7VU1PRKltoWEAkMIUKK8fS7gxVKPwFSaiCrCtVSHuYdh3Cn5JWRleO6bYJH1jBfsAfwYHXl8x0l-IF66P31qd_45c5jrScOzcXh210s-l4qr7LYK-8fPeLeArp0iN8QUX1-uXgzzD397HC7qvK629w7louJovc7LFQz1KkiNGBEc-TJFtEa-gfX1GZnWnebSgjMW8SIg0y2dLkV2YlPeJCWdKag9lgIahsU0JIWPh5Tl-soK1Y5UvldJCDuPNwRrlDSHB0QYaLg25_DO13rMaXZfIiWroVCJa_j6-qaVd44ezo1JuSZyyKuKAJminksxXD2WO8Hfktj_Ub-JBGu8MvFINZH682YxtjX-Bi71eeuHWlTWPXpsMml36LYzHPeOPwt6ou2R-wWLnB7PzJtmbToJmqv7C06FTSEV60cNBc4Tmx14qbtYf8gNBOOfxQR0SoEw-HxMHZO0stGE_EuU0rX0oO7Ztcvp4phxNC8o6-_HkHXsMBzqmj28e7X9aFnQRaK7ZUY4D_bpURqglsqwZ_oyTu8PtqCYGgDM0eUe11-IejfObT0Zcyl_XvT4KlH3F199SFUx_4-zJqa6i6wnmFTqPREC8Bi6nioh15oLCFGjAgVOmqnvBiszRUH8DPodGTqb_BZJ1iQwxYNMO7rLnuJ4-DOIfkSL3QCR4zUM63hwOLnNWqIlckxJ3yfnIc14PEK3nANFwPXNeuIEuxMihi5r8k2DVcXPABJtuUaC1EQsty_B2Mw4Z5-QhgDxfYoVOOKZ0E8NSi3No0RHC6hXPadF4Gf5lAG5TkmUeRETfKN2_MJ2jenbbZzjPiQz-NFWcCGDYPVx5BhRTEwX5X6gf1Acg=w2876-h1128" align="center" width="180" height="85">
      
                    <p style="font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
                      PO Box 4555, Englewood, CO 80155 USA
                    </p>
      
                    <p style="margin-bottom: 0; font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
                      <a target="_blank" style="text-decoration: underline; color: #ffffff;" href="https://www.yfci.org">
                        www.yfci.org
                      </a>
                    </p>
      
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- End footer -->
        </div>
      
        </body>
      
      </html>`

  const SubmitEmail = `<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
  
  <head>
  
    <!--[if gte mso 9]>
    <xml>
      <o:OfficeDocumentSettings>
        <o:AllowPNG/>
        <o:PixelsPerInch>96</o:PixelsPerInch>
      </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
  
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="x-apple-disable-message-reformatting">
    <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->
  
      <!-- Your title goes here -->
      <title>Newsletter</title>
      <!-- End title -->
  
      <!-- Start stylesheet -->
      <style type="text/css">
        a,a[href],a:hover, a:link, a:visited {
          /* This is the link colour */
          text-decoration: none!important;
        }
        .link {
          text-decoration: underline!important;
        }
        p, p:visited {
          /* Fallback paragraph style */
          font-size:15px;
          line-height:24px;
          font-family:'Helvetica', Arial, sans-serif;
          font-weight:300;
          text-decoration:none;
          color: #000000;
        }
        h1 {
          /* Fallback heading style */
          font-size:22px;
          line-height:24px;
          font-family:'Helvetica', Arial, sans-serif;
          font-weight:normal;
          text-decoration:none;
          color: #000000;
        }
        .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td {line-height: 100%;}
        .ExternalClass {width: 100%;}
      </style>
      <!-- End stylesheet -->
  
  </head>
  
    <!-- You can change background colour here -->
    <body style="text-align: center; margin: 0; padding-top: 10px; padding-bottom: 10px; padding-left: 0; padding-right: 0; -webkit-text-size-adjust: 100%;background-color: #f2f4f6; color: #000000" align="center">
    
    <!-- Fallback force center content -->
    <div style="text-align: center;">
      
      <!-- Start container for logo -->
      <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;" width="600">
        <tbody>
          <tr>
            <td style="width: 596px; vertical-align: top; padding-left: 0; padding-right: 0; padding-top: 15px; padding-bottom: 15px;" width="596">
  
              <!-- Your logo is here -->
              <img style="width: 225px; max-width: 225px; height: 37.5px; max-height: 37.5px; text-align: center; color: #ffffff;" alt="Logo" src="https://lh3.googleusercontent.com/fife/AGXqzDlfRrEHMHBwJIlYkFst8Z_itISNPUJ-kC66LgcgMEGvPO7PZF5_oIthfWuMhlUQWKKbEQY0UOiqgNgBfGHaJq_VnW2EVstgjSfua2NY9xHN5b2IwfknO1_qNQKUYA5Sy4HYQxQ5IVkYd1wWJLxRMQAiY4A7FEjurNG9mYJ65woCcZejXbMwP3wT8LcmRiTbhKBIhnsdfLAQ_RZ1AtJquUJpqOH5nDoyEbWdUW2PayTTb22dpLGgyjzXbscoycMQlCFY0b1eHvSFk_9-ShMRNZh6GnWRY0iKzKkt5vtS644-aSx-GrC8o48IzvRtHsGGQ9RtWu_5utITN0Ga3-Ka-ju5boNGru1b7M3zz_RzZcRN6THseHj1cL6_9zZW8LOUf4uuSj7HqM_5PiyoXBj-kT12yzSVQk4g3BzUGeVfmAQ-88PC1Xq7ZFebDWSN7KTv8AknPWoyy22_u7f-cl3cWeZH_cVhMT7YzAckGw47Gpd-pARazQDk_-bUScmWdeHW0dIdicxzm2QIfCVqLqYsVxXEX5AXLkkkm_ue4DBNAqGEuKsImZDxzsQzJDOU94nzaboHuT06cvWmslC53JabY3w0MmTPIQVisNpBbGqn_4UGM_d-waiOkPdl0gIYH7MkM4UYAQrqfm0xMhKzy0GfQnyQQQRnJRcmelhqtSafXjnWURawIDnxDWHrnicIK2c9LpV_GJTQ7lcyU_rmw64K0FRdNSYl62p42CdSWXXP4LPQjmvjKehRd4X5Du1Lp9HPz5TdUMu12HhV1dSO_UX1TQ7W1CcPrtJSo9MVBDH9zqf-A36f013yBLQeL8A6gNX5knJWj65KQKPoe_74En8oyw0qNjKZlcPWHkvCtBNxXpXAtjHp40F5Z6-vtqwuKF7ofbatMyp_KnsGr_ABY-uXBwxv6yTbS5iay6S7B3V_DvPb79IWP97G2Ov0XazB_FJtc6vjmNma-Vi0602z1_j8JzfL9PLlmYgVYSQlcxKDMEzPUcwCDlSOoWsuLRhc3ZTKTvYbwSfBqw-LszB-ZQMICpaiYXwZ2ti6rI-iRObPrGfvySNbg801_XZF_iTJaFMcyir1CZ1PUROM_Mi-YYPOhNLk1G599GmBb28IHc15uKlXLbtUoWD1Gqt6OQq7iqqIGa-bsTs2jdbWvA2wSuUmXRMkXxxAf8d7-y2HaKMMYBG_LhPcwaoD_Ci4MTxPuQ5xg6yDgxhTjsA8IUTxgJWdpC3FN_G0dPVNX6sdfwhbDnlo72AyeQDnWx3cxs_tPiWMoziX6ZXkM2CetPrHdnthbWF6MGx6G2cfTMiWfSHj-bw8Ls2pFOsn5fllVDN_6o_GOMUAW9JqNPmye8ft1KeYvaCnECsUUCSLMXg04WFyxTZmvdRrOhZbCXMAgpRtY-km4CqRj33xeysEhb6ju-JppbXnz6Sqtt51XlIzMidNS0LV8XxGyPicEq5vrPZFrNw-csI5xbxwxWwim5tI2pz0PAlCcozVhqdTjLgk5J9_25z23KVv1T0tz5FjwOot632DwIc5jqUFvUOz0osWSFumMeviyy3zq9lhYrQAkHSi1w=w2876-h1638" align="center" width="225" height="37.5">
  
            </td>
          </tr>
        </tbody>
      </table>
      <!-- End container for logo -->
  
      <!-- Start single column section -->
      <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;" width="600">
          <tbody>
            <tr>
              <td style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 0px; padding-bottom: 40px;" width="596">
  
                <h1 style="font-size: 20px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 600; text-decoration: none; color: #000000;text-align:left;">Dear [BC Name],</h1>
  
                <p style="font-size: 15px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #919293;text-align:left;">I hope this email finds you well. I am writing to inform you that National Director [ND Name] of [Country] has successfully completed the chartering report. We kindly request that you be able to review the charter at your earliest convenience<br/><br/>If everything is in order, please proceed with signing and submitting the report. Should any issues arise, feel free to reach out to us for assistance.<br/><br/>Thank you for your attention to this matter.<br/><br/>Best regards,<br/>The Chartering Team</p>
                <!-- Start button (You can change the background colour by the hex code below) -->
                <a href="https://www.thisischartering.com" target="_blank" style="background-color: #000000; font-size: 15px; line-height: 22px; font-family: 'Helvetica', Arial, sans-serif; font-weight: normal; text-decoration: none; padding: 12px 15px; color: #ffffff; border-radius: 5px; display: inline-block; mso-padding-alt: 0;">
                    <!--[if mso]>
                    <i style="letter-spacing: 25px; mso-font-width: -100%; mso-text-raise: 30pt;">&nbsp;</i>
                  <![endif]-->
  
                    <span style="mso-text-raise: 15pt; color: #ffffff;">Open Chartering</span>
                    <!--[if mso]>
                    <i style="letter-spacing: 25px; mso-font-width: -100%;">&nbsp;</i>
                  <![endif]-->
                </a>
                <!-- End button here -->
  
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End single column section -->
  
        <!-- Start footer -->
        <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #000000;" width="600">
          <tbody>
            <tr>
              <td style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 30px; padding-bottom: 30px;" width="596">
  
                <!-- Your inverted logo is here -->
                <img style="width: 225px; max-width: 225px; height: 37.5px; max-height: 37.5px; text-align: center; color: #ffffff;" alt="Logo" src="https://lh3.googleusercontent.com/fife/AGXqzDlx-zSPxNtGZOTpi1kV5KYmC2tPWs18BVSFSjhrAbLnwmKGFovmIXY6PxdIGG99jcn9oSNZVtvrpVRePFN-nyXppp4ef-ih6tqj9jF4YYU9zhmgXRLSg2ZTkHg3IIfOrQ9i8l0mHR1D7IDGyNBFdr3b9F1NrXehFQ-6hkhSYGDlLPOvpuxV_EYuBx2TW3TYTjHsj-NPC3qdChrTWsa8-n8wdVjSmOlkDbQ7N0cbxNubtF4zr_MDGU5f1vSz2WC9jolst078xyUVkp_Qg0dNyZHMBBXTisNoWMHPtHMG72kCAz-1p7e3G0cPko_QPGnMQvP3BX6B7CJLh-WRt19z0s0gLBqteEb7GAltHOK_IsBx_gnUDPlAa0XwqpBUfUyFFovcpDpD7Al0B3Z8KONirXXNcuVgroTtfhnfvZ83-ARPoF-gRd15jhiVpInMz6sdOpyc625g1mdpxF-C7xfdeBkmO6FSEUrPuVjEDJ-pbJ_u3kxjeQrvbqSSSdacP7cqllB03VVZiQsgsyGr9TCG5c9WyLzS5A0vb1zF9VVMcWKKDFROKeOIp0YW76V-dQwulpWltfegobkQ1lHEyob-UG2TwdI6orePz2uowOhR4QpuRah65hgBh84lVt0h5QE0zGHQy0X-46V-1qr8xOwa5rMaVdOX9u__Uu8ezLL-HZm9C2xnBA8Gkx90-qBKdKNVDs_oQead7VyU-INqh7VU1PRKltoWEAkMIUKK8fS7gxVKPwFSaiCrCtVSHuYdh3Cn5JWRleO6bYJH1jBfsAfwYHXl8x0l-IF66P31qd_45c5jrScOzcXh210s-l4qr7LYK-8fPeLeArp0iN8QUX1-uXgzzD397HC7qvK629w7louJovc7LFQz1KkiNGBEc-TJFtEa-gfX1GZnWnebSgjMW8SIg0y2dLkV2YlPeJCWdKag9lgIahsU0JIWPh5Tl-soK1Y5UvldJCDuPNwRrlDSHB0QYaLg25_DO13rMaXZfIiWroVCJa_j6-qaVd44ezo1JuSZyyKuKAJminksxXD2WO8Hfktj_Ub-JBGu8MvFINZH682YxtjX-Bi71eeuHWlTWPXpsMml36LYzHPeOPwt6ou2R-wWLnB7PzJtmbToJmqv7C06FTSEV60cNBc4Tmx14qbtYf8gNBOOfxQR0SoEw-HxMHZO0stGE_EuU0rX0oO7Ztcvp4phxNC8o6-_HkHXsMBzqmj28e7X9aFnQRaK7ZUY4D_bpURqglsqwZ_oyTu8PtqCYGgDM0eUe11-IejfObT0Zcyl_XvT4KlH3F199SFUx_4-zJqa6i6wnmFTqPREC8Bi6nioh15oLCFGjAgVOmqnvBiszRUH8DPodGTqb_BZJ1iQwxYNMO7rLnuJ4-DOIfkSL3QCR4zUM63hwOLnNWqIlckxJ3yfnIc14PEK3nANFwPXNeuIEuxMihi5r8k2DVcXPABJtuUaC1EQsty_B2Mw4Z5-QhgDxfYoVOOKZ0E8NSi3No0RHC6hXPadF4Gf5lAG5TkmUeRETfKN2_MJ2jenbbZzjPiQz-NFWcCGDYPVx5BhRTEwX5X6gf1Acg=w2876-h1128" align="center" width="180" height="85">
  
                <p style="font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
                  PO Box 4555, Englewood, CO 80155 USA
                </p>
  
                <p style="margin-bottom: 0; font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
                  <a target="_blank" style="text-decoration: underline; color: #ffffff;" href="https://www.yfci.org">
                    www.yfci.org
                  </a>
                </p>
  
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End footer -->
    </div>
  
    </body>
  
  </html>`

  const OTPEmail = `
  <!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
      
      <head>
      
        <!--[if gte mso 9]>
        <xml>
          <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
        </xml>
        <![endif]-->
      
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta name="x-apple-disable-message-reformatting">
        <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->
      
          <!-- Your title goes here -->
          <title>Newsletter</title>
          <!-- End title -->
      
          <!-- Start stylesheet -->
          <style type="text/css">
            a,a[href],a:hover, a:link, a:visited {
              /* This is the link colour */
              text-decoration: none!important;
            }
            .link {
              text-decoration: underline!important;
            }
            p, p:visited {
              /* Fallback paragraph style */
              font-size:15px;
              line-height:24px;
              font-family:'Helvetica', Arial, sans-serif;
              font-weight:300;
              text-decoration:none;
              color: #000000;
            }
            h1 {
              /* Fallback heading style */
              font-size:22px;
              line-height:24px;
              font-family:'Helvetica', Arial, sans-serif;
              font-weight:normal;
              text-decoration:none;
              color: #000000;
            }
            .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td {line-height: 100%;}
            .ExternalClass {width: 100%;}
          </style>
          <!-- End stylesheet -->
      
      </head>
      
        <!-- You can change background colour here -->
        <body style="text-align: center; margin: 0; padding-top: 10px; padding-bottom: 10px; padding-left: 0; padding-right: 0; -webkit-text-size-adjust: 100%;background-color: #f2f4f6; color: #000000" align="center">
        
        <!-- Fallback force center content -->
        <div style="text-align: center;">
          
          <!-- Start container for logo -->
          <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;" width="600">
            <tbody>
              <tr>
                <td style="width: 596px; vertical-align: top; padding-left: 0; padding-right: 0; padding-top: 15px; padding-bottom: 15px;" width="596">
      
                  <!-- Your logo is here -->
                  <img style="width: 300px; max-width: 300px; height: 85px; max-height: 85px; text-align: center; color: #ffffff;" alt="Logo" src="https://yfci.org/wp-content/themes/yfci/resources/images/logo.svg" align="center" width="180" height="85">
      
                </td>
              </tr>
            </tbody>
          </table>
          <!-- End container for logo -->
      
          <!-- Start single column section -->
          <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;" width="600">
              <tbody>
                <tr>
                  <td style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 0px; padding-bottom: 40px;" width="596">
      
                    <h1 style="font-size: 20px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 600; text-decoration: none; color: #000000;">YFCI chartering</h1>
      
                    <p style="font-size: 15px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #919293;">The verification code is ####</p>              
      
                    <!-- Start button (You can change the background colour by the hex code below) -->
                    
                    <!-- End button here -->
      
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- End single column section -->
      
            <!-- Start footer -->
            <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #000000;" width="600">
              <tbody>
                <tr>
                  <td style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 30px; padding-bottom: 30px;" width="596">
      
                    <!-- Your inverted logo is here -->
                    <img style="width: 220px; max-width: 220px; height: 85px; max-height: 85px; text-align: center; color: #ffffff;filter: invert(1);" alt="Logo" src="https://yfci.org/wp-content/themes/yfci/resources/images/logo.svg" align="center" width="180" height="85">
      
                    <p style="font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
                      Address line 1, London, L2 4LN
                    </p>
      
                    <p style="margin-bottom: 0; font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
                      <a target="_blank" style="text-decoration: underline; color: #ffffff;" href="https://thisischartering.com">
                        www.thisischartering.com
                      </a>
                    </p>
      
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- End footer -->
        </div>
      
        </body>
      
      </html>`

  const ReviewEmail = `
  <!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    
    <head>
    
      <!--[if gte mso 9]>
      <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
      <![endif]-->
    
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta name="x-apple-disable-message-reformatting">
      <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->
    
        <!-- Your title goes here -->
        <title>Newsletter</title>
        <!-- End title -->
    
        <!-- Start stylesheet -->
        <style type="text/css">
          a,a[href],a:hover, a:link, a:visited {
            /* This is the link colour */
            text-decoration: none!important;
          }
          .link {
            text-decoration: underline!important;
          }
          p, p:visited {
            /* Fallback paragraph style */
            font-size:15px;
            line-height:24px;
            font-family:'Helvetica', Arial, sans-serif;
            font-weight:300;
            text-decoration:none;
            color: #000000;
          }
          h1 {
            /* Fallback heading style */
            font-size:22px;
            line-height:24px;
            font-family:'Helvetica', Arial, sans-serif;
            font-weight:normal;
            text-decoration:none;
            color: #000000;
          }
          .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td {line-height: 100%;}
          .ExternalClass {width: 100%;}
        </style>
        <!-- End stylesheet -->
    
    </head>
    
      <!-- You can change background colour here -->
      <body style="text-align: center; margin: 0; padding-top: 10px; padding-bottom: 10px; padding-left: 0; padding-right: 0; -webkit-text-size-adjust: 100%;background-color: #f2f4f6; color: #000000" align="center">
      
      <!-- Fallback force center content -->
      <div style="text-align: center;">
        
        <!-- Start container for logo -->
        <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;" width="600">
          <tbody>
            <tr>
              <td style="width: 596px; vertical-align: top; padding-left: 0; padding-right: 0; padding-top: 15px; padding-bottom: 15px;" width="596">
    
                <!-- Your logo is here -->
                <img style="width: 225px; max-width: 225px; height: 37.5px; max-height: 37.5px; text-align: center; color: #ffffff;" alt="Logo" src="https://lh3.googleusercontent.com/fife/AGXqzDlfRrEHMHBwJIlYkFst8Z_itISNPUJ-kC66LgcgMEGvPO7PZF5_oIthfWuMhlUQWKKbEQY0UOiqgNgBfGHaJq_VnW2EVstgjSfua2NY9xHN5b2IwfknO1_qNQKUYA5Sy4HYQxQ5IVkYd1wWJLxRMQAiY4A7FEjurNG9mYJ65woCcZejXbMwP3wT8LcmRiTbhKBIhnsdfLAQ_RZ1AtJquUJpqOH5nDoyEbWdUW2PayTTb22dpLGgyjzXbscoycMQlCFY0b1eHvSFk_9-ShMRNZh6GnWRY0iKzKkt5vtS644-aSx-GrC8o48IzvRtHsGGQ9RtWu_5utITN0Ga3-Ka-ju5boNGru1b7M3zz_RzZcRN6THseHj1cL6_9zZW8LOUf4uuSj7HqM_5PiyoXBj-kT12yzSVQk4g3BzUGeVfmAQ-88PC1Xq7ZFebDWSN7KTv8AknPWoyy22_u7f-cl3cWeZH_cVhMT7YzAckGw47Gpd-pARazQDk_-bUScmWdeHW0dIdicxzm2QIfCVqLqYsVxXEX5AXLkkkm_ue4DBNAqGEuKsImZDxzsQzJDOU94nzaboHuT06cvWmslC53JabY3w0MmTPIQVisNpBbGqn_4UGM_d-waiOkPdl0gIYH7MkM4UYAQrqfm0xMhKzy0GfQnyQQQRnJRcmelhqtSafXjnWURawIDnxDWHrnicIK2c9LpV_GJTQ7lcyU_rmw64K0FRdNSYl62p42CdSWXXP4LPQjmvjKehRd4X5Du1Lp9HPz5TdUMu12HhV1dSO_UX1TQ7W1CcPrtJSo9MVBDH9zqf-A36f013yBLQeL8A6gNX5knJWj65KQKPoe_74En8oyw0qNjKZlcPWHkvCtBNxXpXAtjHp40F5Z6-vtqwuKF7ofbatMyp_KnsGr_ABY-uXBwxv6yTbS5iay6S7B3V_DvPb79IWP97G2Ov0XazB_FJtc6vjmNma-Vi0602z1_j8JzfL9PLlmYgVYSQlcxKDMEzPUcwCDlSOoWsuLRhc3ZTKTvYbwSfBqw-LszB-ZQMICpaiYXwZ2ti6rI-iRObPrGfvySNbg801_XZF_iTJaFMcyir1CZ1PUROM_Mi-YYPOhNLk1G599GmBb28IHc15uKlXLbtUoWD1Gqt6OQq7iqqIGa-bsTs2jdbWvA2wSuUmXRMkXxxAf8d7-y2HaKMMYBG_LhPcwaoD_Ci4MTxPuQ5xg6yDgxhTjsA8IUTxgJWdpC3FN_G0dPVNX6sdfwhbDnlo72AyeQDnWx3cxs_tPiWMoziX6ZXkM2CetPrHdnthbWF6MGx6G2cfTMiWfSHj-bw8Ls2pFOsn5fllVDN_6o_GOMUAW9JqNPmye8ft1KeYvaCnECsUUCSLMXg04WFyxTZmvdRrOhZbCXMAgpRtY-km4CqRj33xeysEhb6ju-JppbXnz6Sqtt51XlIzMidNS0LV8XxGyPicEq5vrPZFrNw-csI5xbxwxWwim5tI2pz0PAlCcozVhqdTjLgk5J9_25z23KVv1T0tz5FjwOot632DwIc5jqUFvUOz0osWSFumMeviyy3zq9lhYrQAkHSi1w=w2876-h1638" align="center" width="225" height="37.5">
    
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End container for logo -->
    
        <!-- Start single column section -->
        <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;" width="600">
            <tbody>
              <tr>
                <td style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 0px; padding-bottom: 40px;" width="596">
    
                  <h1 style="font-size: 20px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 600; text-decoration: none; color: #000000;text-align:left;">Dear [ND Name],</h1>
    
                  <p style="font-size: 15px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #919293;text-align:left;">I hope this email finds you well. I am writing to inform you that Board Chair [BC Name] of [Country] has request you to take some time to revisit your answers.<br/><br/>Reason: [Reason]<br/><br/>Best regards,<br/>The Chartering Team</p>
                  <!-- Start button (You can change the background colour by the hex code below) -->
                  <a href="https://www.thisischartering.com" target="_blank" style="background-color: #000000; font-size: 15px; line-height: 22px; font-family: 'Helvetica', Arial, sans-serif; font-weight: normal; text-decoration: none; padding: 12px 15px; color: #ffffff; border-radius: 5px; display: inline-block; mso-padding-alt: 0;">
                      <!--[if mso]>
                      <i style="letter-spacing: 25px; mso-font-width: -100%; mso-text-raise: 30pt;">&nbsp;</i>
                    <![endif]-->
    
                      <span style="mso-text-raise: 15pt; color: #ffffff;">Open Chartering</span>
                      <!--[if mso]>
                      <i style="letter-spacing: 25px; mso-font-width: -100%;">&nbsp;</i>
                    <![endif]-->
                  </a>
                  <!-- End button here -->
    
                </td>
              </tr>
            </tbody>
          </table>
          <!-- End single column section -->
    
          <!-- Start footer -->
          <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #000000;" width="600">
            <tbody>
              <tr>
                <td style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 30px; padding-bottom: 30px;" width="596">
    
                  <!-- Your inverted logo is here -->
                  <img style="width: 225px; max-width: 225px; height: 37.5px; max-height: 37.5px; text-align: center; color: #ffffff;" alt="Logo" src="https://lh3.googleusercontent.com/fife/AGXqzDlx-zSPxNtGZOTpi1kV5KYmC2tPWs18BVSFSjhrAbLnwmKGFovmIXY6PxdIGG99jcn9oSNZVtvrpVRePFN-nyXppp4ef-ih6tqj9jF4YYU9zhmgXRLSg2ZTkHg3IIfOrQ9i8l0mHR1D7IDGyNBFdr3b9F1NrXehFQ-6hkhSYGDlLPOvpuxV_EYuBx2TW3TYTjHsj-NPC3qdChrTWsa8-n8wdVjSmOlkDbQ7N0cbxNubtF4zr_MDGU5f1vSz2WC9jolst078xyUVkp_Qg0dNyZHMBBXTisNoWMHPtHMG72kCAz-1p7e3G0cPko_QPGnMQvP3BX6B7CJLh-WRt19z0s0gLBqteEb7GAltHOK_IsBx_gnUDPlAa0XwqpBUfUyFFovcpDpD7Al0B3Z8KONirXXNcuVgroTtfhnfvZ83-ARPoF-gRd15jhiVpInMz6sdOpyc625g1mdpxF-C7xfdeBkmO6FSEUrPuVjEDJ-pbJ_u3kxjeQrvbqSSSdacP7cqllB03VVZiQsgsyGr9TCG5c9WyLzS5A0vb1zF9VVMcWKKDFROKeOIp0YW76V-dQwulpWltfegobkQ1lHEyob-UG2TwdI6orePz2uowOhR4QpuRah65hgBh84lVt0h5QE0zGHQy0X-46V-1qr8xOwa5rMaVdOX9u__Uu8ezLL-HZm9C2xnBA8Gkx90-qBKdKNVDs_oQead7VyU-INqh7VU1PRKltoWEAkMIUKK8fS7gxVKPwFSaiCrCtVSHuYdh3Cn5JWRleO6bYJH1jBfsAfwYHXl8x0l-IF66P31qd_45c5jrScOzcXh210s-l4qr7LYK-8fPeLeArp0iN8QUX1-uXgzzD397HC7qvK629w7louJovc7LFQz1KkiNGBEc-TJFtEa-gfX1GZnWnebSgjMW8SIg0y2dLkV2YlPeJCWdKag9lgIahsU0JIWPh5Tl-soK1Y5UvldJCDuPNwRrlDSHB0QYaLg25_DO13rMaXZfIiWroVCJa_j6-qaVd44ezo1JuSZyyKuKAJminksxXD2WO8Hfktj_Ub-JBGu8MvFINZH682YxtjX-Bi71eeuHWlTWPXpsMml36LYzHPeOPwt6ou2R-wWLnB7PzJtmbToJmqv7C06FTSEV60cNBc4Tmx14qbtYf8gNBOOfxQR0SoEw-HxMHZO0stGE_EuU0rX0oO7Ztcvp4phxNC8o6-_HkHXsMBzqmj28e7X9aFnQRaK7ZUY4D_bpURqglsqwZ_oyTu8PtqCYGgDM0eUe11-IejfObT0Zcyl_XvT4KlH3F199SFUx_4-zJqa6i6wnmFTqPREC8Bi6nioh15oLCFGjAgVOmqnvBiszRUH8DPodGTqb_BZJ1iQwxYNMO7rLnuJ4-DOIfkSL3QCR4zUM63hwOLnNWqIlckxJ3yfnIc14PEK3nANFwPXNeuIEuxMihi5r8k2DVcXPABJtuUaC1EQsty_B2Mw4Z5-QhgDxfYoVOOKZ0E8NSi3No0RHC6hXPadF4Gf5lAG5TkmUeRETfKN2_MJ2jenbbZzjPiQz-NFWcCGDYPVx5BhRTEwX5X6gf1Acg=w2876-h1128" align="center" width="180" height="85">
    
                  <p style="font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
                    PO Box 4555, Englewood, CO 80155 USA
                  </p>
    
                  <p style="margin-bottom: 0; font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
                    <a target="_blank" style="text-decoration: underline; color: #ffffff;" href="https://www.yfci.org">
                      www.yfci.org
                    </a>
                  </p>
    
                </td>
              </tr>
            </tbody>
          </table>
          <!-- End footer -->
      </div>
    
      </body>
    
    </html>`

  const CompleteEmail = `
  <!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    
    <head>
    
      <!--[if gte mso 9]>
      <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
      <![endif]-->
    
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta name="x-apple-disable-message-reformatting">
      <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->
    
        <!-- Your title goes here -->
        <title>Newsletter</title>
        <!-- End title -->
    
        <!-- Start stylesheet -->
        <style type="text/css">
          a,a[href],a:hover, a:link, a:visited {
            /* This is the link colour */
            text-decoration: none!important;
          }
          .link {
            text-decoration: underline!important;
          }
          p, p:visited {
            /* Fallback paragraph style */
            font-size:15px;
            line-height:24px;
            font-family:'Helvetica', Arial, sans-serif;
            font-weight:300;
            text-decoration:none;
            color: #000000;
          }
          h1 {
            /* Fallback heading style */
            font-size:22px;
            line-height:24px;
            font-family:'Helvetica', Arial, sans-serif;
            font-weight:normal;
            text-decoration:none;
            color: #000000;
          }
          .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td {line-height: 100%;}
          .ExternalClass {width: 100%;}
        </style>
        <!-- End stylesheet -->
    
    </head>
    
      <!-- You can change background colour here -->
      <body style="text-align: center; margin: 0; padding-top: 10px; padding-bottom: 10px; padding-left: 0; padding-right: 0; -webkit-text-size-adjust: 100%;background-color: #f2f4f6; color: #000000" align="center">
      
      <!-- Fallback force center content -->
      <div style="text-align: center;">
        
        <!-- Start container for logo -->
        <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;" width="600">
          <tbody>
            <tr>
              <td style="width: 596px; vertical-align: top; padding-left: 0; padding-right: 0; padding-top: 15px; padding-bottom: 15px;" width="596">
    
                <!-- Your logo is here -->
                <img style="width: 225px; max-width: 225px; height: 37.5px; max-height: 37.5px; text-align: center; color: #ffffff;" alt="Logo" src="https://lh3.googleusercontent.com/fife/AGXqzDlfRrEHMHBwJIlYkFst8Z_itISNPUJ-kC66LgcgMEGvPO7PZF5_oIthfWuMhlUQWKKbEQY0UOiqgNgBfGHaJq_VnW2EVstgjSfua2NY9xHN5b2IwfknO1_qNQKUYA5Sy4HYQxQ5IVkYd1wWJLxRMQAiY4A7FEjurNG9mYJ65woCcZejXbMwP3wT8LcmRiTbhKBIhnsdfLAQ_RZ1AtJquUJpqOH5nDoyEbWdUW2PayTTb22dpLGgyjzXbscoycMQlCFY0b1eHvSFk_9-ShMRNZh6GnWRY0iKzKkt5vtS644-aSx-GrC8o48IzvRtHsGGQ9RtWu_5utITN0Ga3-Ka-ju5boNGru1b7M3zz_RzZcRN6THseHj1cL6_9zZW8LOUf4uuSj7HqM_5PiyoXBj-kT12yzSVQk4g3BzUGeVfmAQ-88PC1Xq7ZFebDWSN7KTv8AknPWoyy22_u7f-cl3cWeZH_cVhMT7YzAckGw47Gpd-pARazQDk_-bUScmWdeHW0dIdicxzm2QIfCVqLqYsVxXEX5AXLkkkm_ue4DBNAqGEuKsImZDxzsQzJDOU94nzaboHuT06cvWmslC53JabY3w0MmTPIQVisNpBbGqn_4UGM_d-waiOkPdl0gIYH7MkM4UYAQrqfm0xMhKzy0GfQnyQQQRnJRcmelhqtSafXjnWURawIDnxDWHrnicIK2c9LpV_GJTQ7lcyU_rmw64K0FRdNSYl62p42CdSWXXP4LPQjmvjKehRd4X5Du1Lp9HPz5TdUMu12HhV1dSO_UX1TQ7W1CcPrtJSo9MVBDH9zqf-A36f013yBLQeL8A6gNX5knJWj65KQKPoe_74En8oyw0qNjKZlcPWHkvCtBNxXpXAtjHp40F5Z6-vtqwuKF7ofbatMyp_KnsGr_ABY-uXBwxv6yTbS5iay6S7B3V_DvPb79IWP97G2Ov0XazB_FJtc6vjmNma-Vi0602z1_j8JzfL9PLlmYgVYSQlcxKDMEzPUcwCDlSOoWsuLRhc3ZTKTvYbwSfBqw-LszB-ZQMICpaiYXwZ2ti6rI-iRObPrGfvySNbg801_XZF_iTJaFMcyir1CZ1PUROM_Mi-YYPOhNLk1G599GmBb28IHc15uKlXLbtUoWD1Gqt6OQq7iqqIGa-bsTs2jdbWvA2wSuUmXRMkXxxAf8d7-y2HaKMMYBG_LhPcwaoD_Ci4MTxPuQ5xg6yDgxhTjsA8IUTxgJWdpC3FN_G0dPVNX6sdfwhbDnlo72AyeQDnWx3cxs_tPiWMoziX6ZXkM2CetPrHdnthbWF6MGx6G2cfTMiWfSHj-bw8Ls2pFOsn5fllVDN_6o_GOMUAW9JqNPmye8ft1KeYvaCnECsUUCSLMXg04WFyxTZmvdRrOhZbCXMAgpRtY-km4CqRj33xeysEhb6ju-JppbXnz6Sqtt51XlIzMidNS0LV8XxGyPicEq5vrPZFrNw-csI5xbxwxWwim5tI2pz0PAlCcozVhqdTjLgk5J9_25z23KVv1T0tz5FjwOot632DwIc5jqUFvUOz0osWSFumMeviyy3zq9lhYrQAkHSi1w=w2876-h1638" align="center" width="225" height="37.5">
    
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End container for logo -->
    
        <!-- Start single column section -->
        <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;" width="600">
            <tbody>
              <tr>
                <td style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 0px; padding-bottom: 40px;" width="596">
    
                  <h1 style="font-size: 20px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 600; text-decoration: none; color: #000000;text-align:left;">Greetings!</h1>
    
                  <p style="font-size: 15px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #919293;text-align:left;">We are pleased to inform you that the chartering process is now complete for <b>[Country]</b>. We would like to thank everyone involved in making this process successful.<br/><br/>Click this <a href="https://www.africau.edu/images/default/sample.pdf" target="_blank">link</a> for the PDF document with all the details of the chartering process.<br/><br/>Please take a moment to review it and let us know if you have any questions or concerns.<br/><br/>Once again, we would like to thank everyone who was involved for their hard work and dedication in making the chartering process a success.<br/><br/>Sincerely,<br/>The Chartering Team</p>
                  <!-- Start button (You can change the background colour by the hex code below) -->
                </td>
              </tr>
            </tbody>
          </table>
          <!-- End single column section -->
    
          <!-- Start footer -->
          <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #000000;" width="600">
            <tbody>
              <tr>
                <td style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 30px; padding-bottom: 30px;" width="596">
    
                  <!-- Your inverted logo is here -->
                  <img style="width: 225px; max-width: 225px; height: 37.5px; max-height: 37.5px; text-align: center; color: #ffffff;" alt="Logo" src="https://lh3.googleusercontent.com/fife/AGXqzDlx-zSPxNtGZOTpi1kV5KYmC2tPWs18BVSFSjhrAbLnwmKGFovmIXY6PxdIGG99jcn9oSNZVtvrpVRePFN-nyXppp4ef-ih6tqj9jF4YYU9zhmgXRLSg2ZTkHg3IIfOrQ9i8l0mHR1D7IDGyNBFdr3b9F1NrXehFQ-6hkhSYGDlLPOvpuxV_EYuBx2TW3TYTjHsj-NPC3qdChrTWsa8-n8wdVjSmOlkDbQ7N0cbxNubtF4zr_MDGU5f1vSz2WC9jolst078xyUVkp_Qg0dNyZHMBBXTisNoWMHPtHMG72kCAz-1p7e3G0cPko_QPGnMQvP3BX6B7CJLh-WRt19z0s0gLBqteEb7GAltHOK_IsBx_gnUDPlAa0XwqpBUfUyFFovcpDpD7Al0B3Z8KONirXXNcuVgroTtfhnfvZ83-ARPoF-gRd15jhiVpInMz6sdOpyc625g1mdpxF-C7xfdeBkmO6FSEUrPuVjEDJ-pbJ_u3kxjeQrvbqSSSdacP7cqllB03VVZiQsgsyGr9TCG5c9WyLzS5A0vb1zF9VVMcWKKDFROKeOIp0YW76V-dQwulpWltfegobkQ1lHEyob-UG2TwdI6orePz2uowOhR4QpuRah65hgBh84lVt0h5QE0zGHQy0X-46V-1qr8xOwa5rMaVdOX9u__Uu8ezLL-HZm9C2xnBA8Gkx90-qBKdKNVDs_oQead7VyU-INqh7VU1PRKltoWEAkMIUKK8fS7gxVKPwFSaiCrCtVSHuYdh3Cn5JWRleO6bYJH1jBfsAfwYHXl8x0l-IF66P31qd_45c5jrScOzcXh210s-l4qr7LYK-8fPeLeArp0iN8QUX1-uXgzzD397HC7qvK629w7louJovc7LFQz1KkiNGBEc-TJFtEa-gfX1GZnWnebSgjMW8SIg0y2dLkV2YlPeJCWdKag9lgIahsU0JIWPh5Tl-soK1Y5UvldJCDuPNwRrlDSHB0QYaLg25_DO13rMaXZfIiWroVCJa_j6-qaVd44ezo1JuSZyyKuKAJminksxXD2WO8Hfktj_Ub-JBGu8MvFINZH682YxtjX-Bi71eeuHWlTWPXpsMml36LYzHPeOPwt6ou2R-wWLnB7PzJtmbToJmqv7C06FTSEV60cNBc4Tmx14qbtYf8gNBOOfxQR0SoEw-HxMHZO0stGE_EuU0rX0oO7Ztcvp4phxNC8o6-_HkHXsMBzqmj28e7X9aFnQRaK7ZUY4D_bpURqglsqwZ_oyTu8PtqCYGgDM0eUe11-IejfObT0Zcyl_XvT4KlH3F199SFUx_4-zJqa6i6wnmFTqPREC8Bi6nioh15oLCFGjAgVOmqnvBiszRUH8DPodGTqb_BZJ1iQwxYNMO7rLnuJ4-DOIfkSL3QCR4zUM63hwOLnNWqIlckxJ3yfnIc14PEK3nANFwPXNeuIEuxMihi5r8k2DVcXPABJtuUaC1EQsty_B2Mw4Z5-QhgDxfYoVOOKZ0E8NSi3No0RHC6hXPadF4Gf5lAG5TkmUeRETfKN2_MJ2jenbbZzjPiQz-NFWcCGDYPVx5BhRTEwX5X6gf1Acg=w2876-h1128" align="center" width="180" height="85">
    
                  <p style="font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
                    PO Box 4555, Englewood, CO 80155 USA
                  </p>
    
                  <p style="margin-bottom: 0; font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
                    <a target="_blank" style="text-decoration: underline; color: #ffffff;" href="https://www.yfci.org">
                      www.yfci.org
                    </a>
                  </p>
    
                </td>
              </tr>
            </tbody>
          </table>
          <!-- End footer -->
      </div>
    
      </body>
    
    </html>`

    const preOpen =`<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    
    <head>
    
      <!--[if gte mso 9]>
      <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
      <![endif]-->
    
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta name="x-apple-disable-message-reformatting">
      <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->
    
        <!-- Your title goes here -->
        <title>Newsletter</title>
        <!-- End title -->
    
        <!-- Start stylesheet -->
        <style type="text/css">
          a,a[href],a:hover, a:link, a:visited {
            /* This is the link colour */
            text-decoration: none!important;
          }
          .link {
            text-decoration: underline!important;
          }
          p, p:visited {
            /* Fallback paragraph style */
            font-size:15px;
            line-height:24px;
            font-family:'Helvetica', Arial, sans-serif;
            font-weight:300;
            text-decoration:none;
            color: #000000;
          }
          h1 {
            /* Fallback heading style */
            font-size:22px;
            line-height:24px;
            font-family:'Helvetica', Arial, sans-serif;
            font-weight:normal;
            text-decoration:none;
            color: #000000;
          }
          .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td {line-height: 100%;}
          .ExternalClass {width: 100%;}
        </style>
        <!-- End stylesheet -->
    
    </head>
    
      <!-- You can change background colour here -->
      <body style="text-align: center; margin: 0; padding-top: 10px; padding-bottom: 10px; padding-left: 0; padding-right: 0; -webkit-text-size-adjust: 100%;background-color: #f2f4f6; color: #000000" align="center">
      
      <!-- Fallback force center content -->
      <div style="text-align: center;">
        
        <!-- Start container for logo -->
        <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;" width="600">
          <tbody>
            <tr>
              <td style="width: 596px; vertical-align: top; padding-left: 0; padding-right: 0; padding-top: 15px; padding-bottom: 15px;" width="596">
    
                <!-- Your logo is here -->
                <img style="width: 225px; max-width: 225px; height: 37.5px; max-height: 37.5px; text-align: center; color: #ffffff;" alt="Logo" src="https://lh3.googleusercontent.com/fife/AGXqzDlfRrEHMHBwJIlYkFst8Z_itISNPUJ-kC66LgcgMEGvPO7PZF5_oIthfWuMhlUQWKKbEQY0UOiqgNgBfGHaJq_VnW2EVstgjSfua2NY9xHN5b2IwfknO1_qNQKUYA5Sy4HYQxQ5IVkYd1wWJLxRMQAiY4A7FEjurNG9mYJ65woCcZejXbMwP3wT8LcmRiTbhKBIhnsdfLAQ_RZ1AtJquUJpqOH5nDoyEbWdUW2PayTTb22dpLGgyjzXbscoycMQlCFY0b1eHvSFk_9-ShMRNZh6GnWRY0iKzKkt5vtS644-aSx-GrC8o48IzvRtHsGGQ9RtWu_5utITN0Ga3-Ka-ju5boNGru1b7M3zz_RzZcRN6THseHj1cL6_9zZW8LOUf4uuSj7HqM_5PiyoXBj-kT12yzSVQk4g3BzUGeVfmAQ-88PC1Xq7ZFebDWSN7KTv8AknPWoyy22_u7f-cl3cWeZH_cVhMT7YzAckGw47Gpd-pARazQDk_-bUScmWdeHW0dIdicxzm2QIfCVqLqYsVxXEX5AXLkkkm_ue4DBNAqGEuKsImZDxzsQzJDOU94nzaboHuT06cvWmslC53JabY3w0MmTPIQVisNpBbGqn_4UGM_d-waiOkPdl0gIYH7MkM4UYAQrqfm0xMhKzy0GfQnyQQQRnJRcmelhqtSafXjnWURawIDnxDWHrnicIK2c9LpV_GJTQ7lcyU_rmw64K0FRdNSYl62p42CdSWXXP4LPQjmvjKehRd4X5Du1Lp9HPz5TdUMu12HhV1dSO_UX1TQ7W1CcPrtJSo9MVBDH9zqf-A36f013yBLQeL8A6gNX5knJWj65KQKPoe_74En8oyw0qNjKZlcPWHkvCtBNxXpXAtjHp40F5Z6-vtqwuKF7ofbatMyp_KnsGr_ABY-uXBwxv6yTbS5iay6S7B3V_DvPb79IWP97G2Ov0XazB_FJtc6vjmNma-Vi0602z1_j8JzfL9PLlmYgVYSQlcxKDMEzPUcwCDlSOoWsuLRhc3ZTKTvYbwSfBqw-LszB-ZQMICpaiYXwZ2ti6rI-iRObPrGfvySNbg801_XZF_iTJaFMcyir1CZ1PUROM_Mi-YYPOhNLk1G599GmBb28IHc15uKlXLbtUoWD1Gqt6OQq7iqqIGa-bsTs2jdbWvA2wSuUmXRMkXxxAf8d7-y2HaKMMYBG_LhPcwaoD_Ci4MTxPuQ5xg6yDgxhTjsA8IUTxgJWdpC3FN_G0dPVNX6sdfwhbDnlo72AyeQDnWx3cxs_tPiWMoziX6ZXkM2CetPrHdnthbWF6MGx6G2cfTMiWfSHj-bw8Ls2pFOsn5fllVDN_6o_GOMUAW9JqNPmye8ft1KeYvaCnECsUUCSLMXg04WFyxTZmvdRrOhZbCXMAgpRtY-km4CqRj33xeysEhb6ju-JppbXnz6Sqtt51XlIzMidNS0LV8XxGyPicEq5vrPZFrNw-csI5xbxwxWwim5tI2pz0PAlCcozVhqdTjLgk5J9_25z23KVv1T0tz5FjwOot632DwIc5jqUFvUOz0osWSFumMeviyy3zq9lhYrQAkHSi1w=w2876-h1638" align="center" width="225" height="37.5">
    
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End container for logo -->
    
        <!-- Start single column section -->
        <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #ffffff;" width="600">
            <tbody>
              <tr>
                <td style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 0px; padding-bottom: 40px;" width="596">
    
                  <h1 style="font-size: 20px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 600; text-decoration: none; color: #000000;">Chartering 2023 will open soon</h1>
    
                  <p style="font-size: 15px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #919293;text-align:justify">Dear {name},<br><br>
We are grateful for the work you do and who you are! Thank you! 
<br><br>
Online Chartering will open soon - <a href="https://thisischartering.com" target="_blank">thisischartering.com</a>
<br><br>
Quick tips:<br>
•        You will receive your login credentials when chartering opens. If at any time you forget your password, click on “forgot password,” enter your email, and you will receive informaiton on how to reset your password.<br>
•        For the Financial Information section, please input your financial information in your nation’s currency. We will do the exchange rate conversions later. 
<br><br>
In order to submit the forms as complete, both the approval of the National Director and the Board Chair are required. The Board Chair will approve the chartering report after the National Director has entered all the information. Each will need to log in separately to submit the report. Please have the online forms completed by March 31. 
<br><br>
We understand all the work and effort it takes each year for you to fill in these forms, submit your latest financials, pay your charter fee, and more! Your Area Team is available to work with you and help your national program complete everything. Also, our chartering team is here to help if you need it – so please don’t hesitate to write if you need help!
<br><br>
                    If you have additional questions, email us at <a href="mailto:chartering@yfci.org">chartering@yfci.org</a>.
<br><br>Cheers,<br>The Chartering Team</p>              
                  <!-- Start button (You can change the background colour by the hex code below) -->
                </td>
              </tr>
            </tbody>
          </table>
          <!-- End single column section -->
    
          <!-- Start footer -->
          <table align="center" style="text-align: center; vertical-align: top; width: 600px; max-width: 600px; background-color: #000000;" width="600">
            <tbody>
              <tr>
                <td style="width: 596px; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 30px; padding-bottom: 30px;" width="596">
    
                  <!-- Your inverted logo is here -->
                  <img style="width: 225px; max-width: 225px; height: 37.5px; max-height: 37.5px; text-align: center; color: #ffffff;" alt="Logo" src="https://lh3.googleusercontent.com/fife/AGXqzDlx-zSPxNtGZOTpi1kV5KYmC2tPWs18BVSFSjhrAbLnwmKGFovmIXY6PxdIGG99jcn9oSNZVtvrpVRePFN-nyXppp4ef-ih6tqj9jF4YYU9zhmgXRLSg2ZTkHg3IIfOrQ9i8l0mHR1D7IDGyNBFdr3b9F1NrXehFQ-6hkhSYGDlLPOvpuxV_EYuBx2TW3TYTjHsj-NPC3qdChrTWsa8-n8wdVjSmOlkDbQ7N0cbxNubtF4zr_MDGU5f1vSz2WC9jolst078xyUVkp_Qg0dNyZHMBBXTisNoWMHPtHMG72kCAz-1p7e3G0cPko_QPGnMQvP3BX6B7CJLh-WRt19z0s0gLBqteEb7GAltHOK_IsBx_gnUDPlAa0XwqpBUfUyFFovcpDpD7Al0B3Z8KONirXXNcuVgroTtfhnfvZ83-ARPoF-gRd15jhiVpInMz6sdOpyc625g1mdpxF-C7xfdeBkmO6FSEUrPuVjEDJ-pbJ_u3kxjeQrvbqSSSdacP7cqllB03VVZiQsgsyGr9TCG5c9WyLzS5A0vb1zF9VVMcWKKDFROKeOIp0YW76V-dQwulpWltfegobkQ1lHEyob-UG2TwdI6orePz2uowOhR4QpuRah65hgBh84lVt0h5QE0zGHQy0X-46V-1qr8xOwa5rMaVdOX9u__Uu8ezLL-HZm9C2xnBA8Gkx90-qBKdKNVDs_oQead7VyU-INqh7VU1PRKltoWEAkMIUKK8fS7gxVKPwFSaiCrCtVSHuYdh3Cn5JWRleO6bYJH1jBfsAfwYHXl8x0l-IF66P31qd_45c5jrScOzcXh210s-l4qr7LYK-8fPeLeArp0iN8QUX1-uXgzzD397HC7qvK629w7louJovc7LFQz1KkiNGBEc-TJFtEa-gfX1GZnWnebSgjMW8SIg0y2dLkV2YlPeJCWdKag9lgIahsU0JIWPh5Tl-soK1Y5UvldJCDuPNwRrlDSHB0QYaLg25_DO13rMaXZfIiWroVCJa_j6-qaVd44ezo1JuSZyyKuKAJminksxXD2WO8Hfktj_Ub-JBGu8MvFINZH682YxtjX-Bi71eeuHWlTWPXpsMml36LYzHPeOPwt6ou2R-wWLnB7PzJtmbToJmqv7C06FTSEV60cNBc4Tmx14qbtYf8gNBOOfxQR0SoEw-HxMHZO0stGE_EuU0rX0oO7Ztcvp4phxNC8o6-_HkHXsMBzqmj28e7X9aFnQRaK7ZUY4D_bpURqglsqwZ_oyTu8PtqCYGgDM0eUe11-IejfObT0Zcyl_XvT4KlH3F199SFUx_4-zJqa6i6wnmFTqPREC8Bi6nioh15oLCFGjAgVOmqnvBiszRUH8DPodGTqb_BZJ1iQwxYNMO7rLnuJ4-DOIfkSL3QCR4zUM63hwOLnNWqIlckxJ3yfnIc14PEK3nANFwPXNeuIEuxMihi5r8k2DVcXPABJtuUaC1EQsty_B2Mw4Z5-QhgDxfYoVOOKZ0E8NSi3No0RHC6hXPadF4Gf5lAG5TkmUeRETfKN2_MJ2jenbbZzjPiQz-NFWcCGDYPVx5BhRTEwX5X6gf1Acg=w2876-h1128" align="center" width="180" height="85">
    
                  <p style="font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
                    PO Box 4555, Englewood, CO 80155 USA
                  </p>
    
                  <p style="margin-bottom: 0; font-size: 13px; line-height: 24px; font-family: 'Helvetica', Arial, sans-serif; font-weight: 400; text-decoration: none; color: #ffffff;">
                    <a target="_blank" style="text-decoration: underline; color: #ffffff;" href="https://www.yfci.org">
                      www.yfci.org
                    </a>
                  </p>
    
                </td>
              </tr>
            </tbody>
          </table>
          <!-- End footer -->
      </div>
    
      </body>
    
    </html>`